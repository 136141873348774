import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

import moment from "moment";
window.moment = moment;

toastr = require("toastr");

import select2 from "select2";

window.select2 = select2();
