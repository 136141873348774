// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";

import * as ActiveStorage from "@rails/activestorage";
import "./jquery";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

/**
 * Import Bootstrap JS
 */
import "bootstrap";

/**
 * Import Application Custom CSS
 */
import "./styles/application.scss";

/**
 * Import/Require AdminLTE Package
 */
require("admin-lte");

/**
 * Import Adminlte Plugins
 */

import Inputmask from "inputmask";
import Stepper from "bs-stepper";

import "@fortawesome/fontawesome-free/js/all";
import "admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4";
import "dropzone/dist/dropzone";
import "jquery-validation/dist/jquery.validate";
import "jquery-validation/dist/additional-methods";
import "admin-lte/plugins/datatables/jquery.dataTables";
import "admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4";
import "admin-lte/plugins/datatables-responsive/js/dataTables.responsive";
import "admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4";
import "datatables.net-buttons/js/dataTables.buttons";
import jsZip from 'jszip';
window.JSZip = jsZip;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4";

let dataTable = null;
let dataTableNum = null;

document.addEventListener("turbolinks:before-cache", function () {
  if (dataTable !== null) {
    dataTable.destroy();
    dataTable = null;
  }

  if (dataTableNum !== null) {
    dataTableNum.destroy();
    dataTableNum = null;
  }

  if (dataTableBtn !== null) {
    dataTableBtn.destroy();
    dataTableBtn = null;
  }
});
//add an event listener to use a specific library
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  Inputmask("999.999.999-99").mask($(".cpf"));
  Inputmask("99.999.999/9999-99").mask($(".cnpj"));
  Inputmask("(99) 99999999[9]").mask($(".phone"));
  Inputmask("99.999-999").mask($(".cep"));
  Inputmask("99999999999").mask($(".nis"));

  // //Initialize Select2 Elements
  $(".select2").select2({
    theme: "bootstrap4",
    minimumResultsForSearch: Infinity,
  });
  $(".select2bs4").select2({
    theme: "bootstrap4",
  });

  $(".date").datetimepicker({
    format: "DD/MM/YYYY",
    locale: "pt-br",
  });
  $(".datetime").datetimepicker({
    format: "DD/MM/YYYY HH:mm",
    locale: "pt-br",
  });

  if ($(".bs-stepper").length) {
    window.stepper = new Stepper($(".bs-stepper")[0]);
  }

  $.fn.dataTable.Buttons.defaults.dom.button.className = 'btn btn-primary';

  dataTable = $(".datatable").DataTable({
    language: {
      url: "https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json",
    },
    paging: true,
    lengthChange: true,
    searching: true,
    ordering: true,
    info: true,
    autoWidth: false,
    responsive: true,
    order: [],
  });

  dataTableNum = $(".datatable-num").DataTable({
    language: {
      url: "https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json",
    },
    paging: true,
    lengthChange: true,
    searching: true,
    ordering: true,
    info: true,
    autoWidth: false,
    responsive: true,
    columnDefs: [{ type: "num", targets: 0 }],
    order: [],
  });

  dataTableBtn = $(".datatable-btn").DataTable({
    language: {
      url: "https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json",
    },
    paging: true,
    lengthChange: true,
    searching: true,
    ordering: true,
    info: true,
    autoWidth: false,
    responsive: true,
    dom: '<"text-right mb-3"B><"row"<"col-md-6"l><"col-md-6"f>>rt<"row"<"col-md-6"i><"col-md-6"p>>',
    buttons: [{
      extend: 'collection',
      text: 'Export',
      buttons: ['csv', 'pdf', 'excel'],
    }],
  });

  $.validator.setDefaults({
    submitHandler: function () {
      alert("Form successful submitted!");
    },
    errorElement: "span",
    errorPlacement: function (error, element) {
      error.addClass("invalid-feedback");
      element.closest(".form-group").append(error);
    },
    highlight: function (element, errorClass, validClass) {
      $(element).addClass("is-invalid");
    },
    unhighlight: function (element, errorClass, validClass) {
      $(element).removeClass("is-invalid");
    },
  });

  $.validator.addMethod(
    "validateCPF",
    function (value, element) {
      value = value.replace(".", "");
      value = value.replace(".", "");
      cpf = value.replace("-", "");
      while (cpf.length < 11) cpf = "0" + cpf;
      var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
      var a = [];
      var b = new Number();
      var c = 11;
      for (i = 0; i < 11; i++) {
        a[i] = cpf.charAt(i);
        if (i < 9) b += a[i] * --c;
      }
      if ((x = b % 11) < 2) {
        a[9] = 0;
      } else {
        a[9] = 11 - x;
      }
      b = 0;
      c = 11;
      for (y = 0; y < 10; y++) b += a[y] * c--;
      if ((x = b % 11) < 2) {
        a[10] = 0;
      } else {
        a[10] = 11 - x;
      }
      if (cpf.charAt(9) != a[9] || cpf.charAt(10) != a[10] || cpf.match(expReg))
        return false;
      return true;
    },
    "Invalid CPF."
  );

  $("#loginForm").validate({
    rules: {
      cpf: {
        required: true,
        validateCPF: true,
      },
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
        minlength: 5,
      },
      terms: {
        required: true,
      },
    },
    messages: {
      cpf: {
        required: "Digite o seu CPF!",
        verificaCPF: "CPF inválido",
      },
      email: {
        required: "Please enter a email address",
        email: "Please enter a vaild email address",
      },
      password: {
        required: "Please provide a password",
        minlength: "Your password must be at least 5 characters long",
      },
      terms: "Please accept our terms",
    },
  });

  if ($(".line-opt").length) {
    if ($(".line-opt").val() != "") getProjectsByLine($(".line-opt").val());
    $(".line-opt").on("change", function () {
      getProjectsByLine($(this).val());
    });

    function getProjectsByLine(line) {
      var project = $(".project-opt").val();
      $(".project-opt").find("option").remove();
      $(".project-opt").append(new Option("Escolha...", "", true, false));
      // get research line by area
      $.getJSON("/admin/get_projects_by_line/" + line).done(function (json) {
        console.log(json);
        if (json.length) {
          //set option to research line select field .line-opt
          $.each(json, function (k, v) {
            var selected;
            if (v.id == project) selected = true;
            else selected = false;
            $(".project-opt").append(new Option(v.name, v.id, false, selected));
          });
        }
      });
    }
  }

  if ($(".area-opt").length) {
    if ($(".area-opt").val() != "") getResearchLines($(".area-opt").val());
    $(".area-opt").on("change", function () {
      getResearchLines($(this).val());
    });

    function getResearchLines(area) {
      var line = $(".line-opt").val();
      $(".line-opt").find("option").remove();
      $(".line-opt").append(new Option("Escolha...", "", true, false));
      // get research line by area
      $.getJSON("/admin/get_research_lines/" + area).done(function (json) {
        console.log(json);
        if (json.length) {
          //set option to research line select field .line-opt
          $.each(json, function (k, v) {
            var selected;
            if (v.id == line) selected = true;
            else selected = false;
            $(".line-opt").append(new Option(v.name, v.id, false, selected));
          });
        }
      });
    }
  }

  if ($(".topic-status-opt").length) {
    $(".topic-status-opt").each(function () {
      toogleTopicStatus($(this));
    });

    $(".topic-status-opt").on("change", function () {
      toogleTopicStatus($(this));
    });

    function toogleTopicStatus(topic) {
      var status = topic.val();
      var topic_div = topic.closest(".row").find(".topic-status-text-label");
      if (status == "fulfilled") {
        $(".topic-status-text", topic_div).attr("disabled", true);
        topic_div.hide();
      } else {
        $(".topic-status-text", topic_div).attr("disabled", false);
        topic_div.show();
      }
    }
  }

  //Category Option to user nested attributes
  //TODO: Remove this block and replace by Member Type (.member-type)
  if ($(".category-option").length) {
    if ($(".category-option").val() != "")
      setExternalOption($(".category-option").val());
    $(".category-option").on("change", function () {
      setExternalOption($(this).val());
    });
  }

  function setExternalOption(option) {
    if (option == "external" || option == "suplent_external") {
      // $(".external").attr("disabled", false);
      $(".external-col").show();
      setDocTypeMask($(".doc-type").val());
    } else {
      // $(".external").attr("disabled", true);
      $(".external-col").hide();
    }
  }

  //Member Type Option to user Association or Nested Attributes
  if ($(".member-type").length) {
    console.log("Member Type Length");
    console.log($(".member-type").val());
    if ($(".member-type").val() != "") setMemberType($(".member-type").val());
    $(".member-type").on("change", function () {
      setMemberType($(this).val());
    });
  }

  function setMemberType(memberType) {
    console.log("setMemberType");
    if (memberType == "external" || memberType == "suplent_external") {
      $(".member-external").show();
      $(".member-internal").hide();
      $(".member-internal select").attr("disabled", true);
      $(".member-qualification").hide();
      $(".member-qualification select").attr("disabled", true);
      $(".member-extra").hide();
      $(".member-extra select").attr("disabled", true);

      $(".doc-type").attr("disabled", false);
      setDocType($(".doc-type").val());
    } else {
      $(".member-external").hide();
      $(".member-external input, .member-external select").attr(
        "disabled",
        true
      );

      if (memberType == "qualification_member") {
        $(".member-qualification").show();
        $(".member-qualification select").attr("disabled", false);
        $(".member-internal").hide();
        $(".member-internal select").attr("disabled", true);
        $(".member-extra").hide();
        $(".member-extra select").attr("disabled", true);
      } else {
        $(".member-qualification").hide();
        $(".member-qualification select").attr("disabled", true);

        if (memberType == "extra_coadvisor") {
          $(".member-internal").hide();
          $(".member-internal select").attr("disabled", true);
          $(".member-extra").show();
          $(".member-extra select").attr("disabled", false);
        } else {
          $(".member-extra").hide();
          $(".member-extra select").attr("disabled", true);

          $(".member-internal").show();
          $(".member-internal select").attr("disabled", false);
        }
      }
    }
  }

  if ($(".doc-type").length) {
    if (
      $(".doc-type").val() != "" &&
      $(".doc-type").attr("disabled") !== "disabled"
    )
      setDocType($(".doc-type").val());
    $(".doc-type").on("change", function () {
      setDocType($(this).val());
    });
  }

  function setDocType(docType) {
    console.log("setDocType");
    if (docType == "cpf") {
      $(".cpf").attr("disabled", false);
      $(".doc-cpf").show();
      $(".passport").attr("disabled", true);
      $(".doc-passport").hide();
      console.log("adicionar CPF");
    } else {
      $(".cpf").attr("disabled", true);
      $(".doc-cpf").hide();
      $(".passport").attr("disabled", false);
      $(".doc-passport").show();
    }
  }

  //Project Members AJAX
  if ($("form.edit_project_member").length) {
    getUserAttributesByEmail(
      $("#project_member_user_attributes_email").val(),
      "#project_member_user"
    );
  }

  $("#project_member_user_attributes_email").on("change", function () {
    var email = $(this).val();
    //ajax get user by email and if user was found set fields
    getUserAttributesByEmail(email, "#project_member_user");
  });

  //Essay Coadvisor Internal
  if ($(".coadvisor-internal").length) {
    var user_id = $(".coadvisor-internal").val();
    if (user_id != "") {
      getUserAttributesById(user_id).done(function (user) {
        $("#coadvisor_lattes").attr("disabled", false);
        $("#coadvisor_score").attr("disabled", false);
        if (user.id) {
          if (user.lattes != "") {
            $("#coadvisor_lattes").val(user.lattes);
          }
          if (user.score != "") {
            $("#coadvisor_score").val(user.score);
          }
        } else {
          $("#coadvisor_lattes").val("");
          $("#coadvisor_score").val("");
        }
      });
    }
  }
  $(".coadvisor-internal").on("change", function () {
    var user_id = $(this).val();
    getUserAttributesById(user_id).done(function (user) {
      $("#coadvisor_lattes").attr("disabled", false);
      $("#coadvisor_score").attr("disabled", false);
      if (user.id) {
        if (user.lattes != "") {
          $("#coadvisor_lattes").val(user.lattes);
        }
        if (user.score != "") {
          $("#coadvisor_score").val(user.score);
        }
      } else {
        $("#coadvisor_lattes").val("");
        $("#coadvisor_score").val("");
      }
    });
  });
  // if ($("form.edit_coadvisor").length) {
  //   getUserAttributesByEmail(
  //     $("#coadvisor_user_attributes_email").val(),
  //     "#coadvisor_user"
  //   );
  // }

  // $("#coadvisor_user_attributes_email").on("change", function () {
  //   var email = $(this).val();
  //   //ajax get user by email and if user was found set fields
  //   getUserAttributesByEmail(email, "#coadvisor_user");
  // });

  if ($(".edit-essay-status").length) {
    setEssayComments($(".edit-essay-status").val());
    $(".edit-essay-status").on("change", function () {
      setEssayComments($(this).val());
    });
  }

  function setEssayComments(status) {
    if (status === "cancelled") {
      $(".essay-comments").show();
      $("#essay-comments").attr("disabled", false);
    } else {
      $(".essay-comments").hide();
      $("#essay-comments").attr("disabled", true);
    }
  }

  //Publication Author AJAX
  if ($("form.edit_author").length) {
    getUserAttributesByEmail(
      $("#author_user_attributes_email").val(),
      "#author_user"
    );
  }

  $("#author_user_attributes_email").on("change", function () {
    var email = $(this).val();
    //ajax get user by email and if user was found set fields
    getUserAttributesByEmail(email, "#author_user");
  });

  $("#member_document_type_cpf").on("change", function () {
    var cpf = $(this).val();
    var prefix = $(this).attr("data-prefix");
    //ajax get user by email and if user was found set fields
    getUserAttributesByDocument("cpf", cpf, prefix);
  });

  if (
    $("#member_document_type_cpf").length &&
    $("#member_document_type_cpf").attr("disabled") !== "disabled"
  ) {
    var prefix = $("#member_document_type_cpf").attr("data-prefix");
    editUserAttributes(prefix, "cpf");
  }

  $("#member_document_type_passport").on("change", function () {
    var passport = $(this).val();
    var prefix = $(this).attr("data-prefix");
    //ajax get user by email and if user was found set fields
    getUserAttributesByDocument("passport", passport, prefix);
  });

  if (
    $("#member_document_type_passport").length &&
    $("#member_document_type_passport").attr("disabled") !== "disabled"
  ) {
    var prefix = $("#member_document_type_passport").attr("data-prefix");
    editUserAttributes(prefix, "passport");
  }

  function getUserAttributesById(id) {
    return $.getJSON("/admin/get_user/id/" + id);
  }

  function getUserAttributesByEmail(email, prefix) {
    if (validateEmail(email)) {
      $.getJSON("/admin/get_user/email/" + email).done(function (json) {
        console.log(json);
        setUserAttributes(json, prefix, "email");
      });
    }
  }

  function getUserAttributesByDocument(type, number, prefix) {
    if (type == "cpf") {
      if (validateCPF(number)) {
        $.getJSON("/admin/get_user/cpf/" + number).done(function (json) {
          console.log(json);
          setUserAttributes(json, prefix, "cpf");
        });
      }
    } else {
      $.getJSON("/admin/get_user/passport/" + number).done(function (json) {
        console.log(json);
        setUserAttributes(json, prefix, "passport");
      });
    }
  }

  function setUserAttributes(user, prefix, from) {
    if (user.id) {
      console.log("set User attributes with User ID: " + user.id);
      $(prefix + "_attributes_id").attr("disabled", false);
      $(prefix + "_attributes_document_number").attr("disabled", false);
      $(prefix + "_attributes_username").attr("disabled", true);
      $(prefix + "_attributes_name").attr("disabled", true);
      $(prefix + "_attributes_nationality").attr("disabled", true);
      $(prefix + "_attributes_degree_level").attr("disabled", true);
      $(prefix + "_attributes_institution").attr("disabled", true);

      $(prefix + "_attributes_id").val(user.id);
      $(prefix + "_attributes_username").val(user.username);
      $(prefix + "_attributes_name").val(user.name);
      if (from == "cpf" || from == "passport") {
        $(prefix + "_attributes_email").val(user.email);
        $(prefix + "_attributes_email").attr("disabled", true);
      }
      $(prefix + "_attributes_document_type").val(user.document_type);
      if (from == "email") {
        $(prefix + "_attributes_document_type").attr("disabled", true);
      }
      $(prefix + "_attributes_document_number").val(user.document_number);
      $(prefix + "_attributes_nationality").val(user.nationality);
      $(prefix + "_attributes_degree_level").val(user.degree_level);
      $(prefix + "_attributes_institution").val(user.institution);
      if (user.lattes !== "") {
        console.log("set lattes");
        console.log(user.lattes);
        $('[id$="_lattes"]').val(user.lattes);
      }
      if (user.score !== "") {
        console.log("set score");
        console.log(user.score);
        $('[id$="_score"]').val(user.score);
      }
    } else {
      console.log("set User attributes without User ID: ");
      $(prefix + "_attributes_id").attr("disabled", true);
      $(prefix + "_attributes_username").attr("disabled", false);
      $(prefix + "_attributes_name").attr("disabled", false);
      $(prefix + "_attributes_document_number").attr("disabled", false);
      $(prefix + "_attributes_nationality").attr("disabled", false);
      $(prefix + "_attributes_degree_level").attr("disabled", false);
      $(prefix + "_attributes_institution").attr("disabled", false);

      $(prefix + "_attributes_id").val("");
      $(prefix + "_attributes_username").val("");
      $(prefix + "_attributes_name").val("");
      if (from == "cpf" || from == "passport") {
        $(prefix + "_attributes_email").val("");
        $(prefix + "_attributes_email").attr("disabled", false);
      }
      if (from == "email") {
        $(prefix + "_attributes_document_type").val("");
        $(prefix + "_attributes_document_type").attr("disabled", false);
      }
      $(prefix + "_attributes_document_number").val("");
      $(prefix + "_attributes_nationality").val("");
      $(prefix + "_attributes_degree_level").val("");
      $(prefix + "_attributes_institution").val("");
      $('[id$="_lattes"]').val("");
      $('[id$="_score"]').val("");
    }
  }

  function editUserAttributes(prefix, from) {
    if ($(prefix + "_attributes_id").val() !== "") {
      console.log("Edit User Attributes with ID");
      $(prefix + "_attributes_id").attr("disabled", false);
      $(prefix + "_attributes_username").attr("disabled", true);
      $(prefix + "_attributes_name").attr("disabled", true);
      if (from == "cpf" || from == "passport") {
        $(prefix + "_attributes_email").attr("disabled", true);
      }
      if (from == "email") {
        $(prefix + "_attributes_document_type").attr("disabled", true);
      }
      $(prefix + "_attributes_document_number").attr("disabled", true);
      $(prefix + "_attributes_nationality").attr("disabled", true);
      $(prefix + "_attributes_degree_level").attr("disabled", true);
      $(prefix + "_attributes_institution").attr("disabled", true);
    } else {
      console.log("Edit User Attributes without ID");
      $(prefix + "_attributes_id").attr("disabled", true);
      $(prefix + "_attributes_id").attr("disabled", false);
      $(prefix + "_attributes_username").attr("disabled", false);
      $(prefix + "_attributes_name").attr("disabled", false);
      if (from == "cpf" || from == "passport") {
        $(prefix + "_attributes_email").attr("disabled", false);
      }
      if (from == "email") {
        $(prefix + "_attributes_document_type").attr("disabled", false);
      }
      $(prefix + "_attributes_document_number").attr("disabled", false);
      $(prefix + "_attributes_nationality").attr("disabled", false);
      $(prefix + "_attributes_degree_level").attr("disabled", false);
      $(prefix + "_attributes_institution").attr("disabled", false);
    }
  }

  //Publication 1
  if ($(".pub-type").length) {
    if ($(".pub-type").val() != "") setPubType($(".pub-type").val());
    $(".pub-type").on("change", function () {
      setPubType($(this).val());
    });
  }

  function setPubType(pub_type) {
    if (pub_type == "article") {
      $(".article-div").show();
      $("#article_status").show();
      $("#article_status select").attr("disabled", false);

      $("#patent_code").hide();
      $("#patent_code input").attr("disabled", true);
    } else {
      $("#patent_code").show();
      $("#patent_code input").attr("disabled", false);

      $(".article-fields").hide();
      $(".article-div").hide();
      $(".article-fields input, .article-fields select").attr("disabled", true);
    }
  }

  //Publication 2
  if ($(".pub2-type").length) {
    if ($(".pub2-type").val() != "") setPub2Type($(".pub2-type").val());
    $(".pub2-type").on("change", function () {
      setPub2Type($(this).val());
    });
  }

  function setPub2Type(pub_type) {
    if (pub_type == "article") {
      $("#article2_status").show();
      $("#article2_status select").attr("disabled", false);

      $("#patent2_code").hide();
      $("#patent2_code input").attr("disabled", true);
    } else {
      $("#patent2_code").show();
      $("#patent2_code input").attr("disabled", false);

      $(".article2-fields").hide();
      $(".article2-fields input, .article2-fields select").attr(
        "disabled",
        true
      );
    }
  }

  // Article 1
  if ($(".article-status").length) {
    if ($(".article-status").val() != "")
      setArticleStatus($(".article-status").val());
    $("#article_status select").on("change", function () {
      setArticleStatus($(this).val());
    });
  }

  function setArticleStatus(status) {
    switch (status) {
      case "submitted":
        $("#article_receipt").show();
        $("#article_receipt input").attr("disabled", false);

        $("#article_file").hide();
        $("#article_file input").attr("disabled", true);
        $("#article_doi").hide();
        $("#article_doi input").attr("disabled", true);
        break;

      case "published":
        $("#article_doi").show();
        $("#article_doi input").attr("disabled", false);

        $("#article_file").hide();
        $("#article_file input").attr("disabled", true);
        $("#article_receipt").hide();
        $("#article_receipt input").attr("disabled", true);
        break;

      case "to_be_submitted":
        $("#article_file").show();
        $("#article_file input").attr("disabled", false);

        $("#article_doi").hide();
        $("#article_doi input").attr("disabled", true);
        $("#article_receipt").hide();
        $("#article_receipt input").attr("disabled", true);
        break;

      default:
        break;
    }
  }

  // Article 2
  if ($(".article2-status").length) {
    if ($(".article2-status").val() != "")
      setArticle2Status($(".article2-status").val());
    $("#article2_status select").on("change", function () {
      setArticle2Status($(this).val());
    });
  }

  function setArticle2Status(status) {
    switch (status) {
      case "submitted":
        $("#article2_receipt").show();
        $("#article2_receipt input").attr("disabled", false);

        $("#article2_doi").hide();
        $("#article2_doi input").attr("disabled", true);
        break;

      case "published":
        $("#article2_doi").show();
        $("#article2_doi input").attr("disabled", false);

        $("#article2_receipt").hide();
        $("#article2_receipt input").attr("disabled", true);
        break;

      default:
        break;
    }
  }

  if ($(".no-member-text-open").length) {
    $(".no-member-text-open").on("click", function (e) {
      e.preventDefault();
      $(".no-member-text-form").show();
    });
  }

  if ($("#presentation_no_qualification_members").length) {
    setNoQualificationMemberText(
      $("#presentation_no_qualification_members").prop("checked")
    );
    $("#presentation_no_qualification_members").on("change", function () {
      setNoQualificationMemberText($(this).prop("checked"));
    });
  }

  function setNoQualificationMemberText(status) {
    if (status == true) {
      $(".presentation_no_qualification_members_comments").show();
      $("#presentation_no_qualification_members_comments").attr(
        "disabled",
        false
      );
    } else {
      $(".presentation_no_qualification_members_comments").hide();
      $("#presentation_no_qualification_members_comments").attr(
        "disabled",
        true
      );
    }
  }

  if ($(".essay-status-filter").length) {
    $(".essay-status-filter").on("change", function () {
      if ($(this).val() !== "") {
        $(location).prop("href", "/admin/essays?status=" + $(this).val());
      } else {
        $(location).prop("href", "/admin/essays");
      }
    });
  }

  if ($(".assessment-status-filter").length) {
    $(".assessment-status-filter").on("change", function () {
      $(location).prop("href", "/admin/dashboard/assessments/" + $(this).val());
    });
  }

  if ($(".presentation-status-filter").length) {
    $(".presentation-status-filter").on("change", function () {
      $(location).prop(
        "href",
        "/admin/dashboard/presentations/" + $(this).val()
      );
    });
  }

  if ($(".coadvisor-status-filter").length) {
    $(".coadvisor-status-filter").on("change", function () {
      $(location).prop("href", "/admin/dashboard/coadvisors/" + $(this).val());
    });
  }

  if ($(".order-status-filter").length) {
    $(".order-status-filter").on("change", function () {
      if ($(this).val() !== "") {
        $(location).prop("href", "/admin/orders?status=" + $(this).val());
      } else {
        $(location).prop("href", "/admin/orders");
      }
    });
  }

  if ($(".transaction-status-filter").length) {
    $(".transaction-status-filter").on("change", function () {
      if ($(this).val() !== "") {
        $(location).prop("href", "/admin/transactions?status=" + $(this).val());
      } else {
        $(location).prop("href", "/admin/transactions");
      }
    });
  }

  if ($(".payroll-status-filter").length) {
    $(".payroll-status-filter").on("change", function () {
      if ($(this).val() !== "") {
        $(location).prop("href", "/admin/payrolls?status=" + $(this).val());
      } else {
        $(location).prop("href", "/admin/payrolls");
      }
    });
  }

  if ($(".cancellation-status-filter").length) {
    $(".cancellation-status-filter").on("change", function () {
      $(location).prop(
        "href",
        "/admin/dashboard/advisor_cancellations/" + $(this).val()
      );
    });
  }
});

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function validateCPF(value) {
  value = value.replace(".", "");
  value = value.replace(".", "");
  var cpf = value.replace("-", "");
  while (cpf.length < 11) cpf = "0" + cpf;
  var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
  var a = [];
  var b = new Number();
  var c = 11;
  for (var i = 0; i < 11; i++) {
    a[i] = cpf.charAt(i);
    if (i < 9) b += a[i] * --c;
  }
  if (b % 11 < 2) {
    a[9] = 0;
  } else {
    a[9] = 11 - (b % 11);
  }
  b = 0;
  c = 11;
  for (var y = 0; y < 10; y++) b += a[y] * c--;
  if (b % 11 < 2) {
    a[10] = 0;
  } else {
    a[10] = 11 - (b % 11);
  }
  if (cpf.charAt(9) != a[9] || cpf.charAt(10) != a[10] || cpf.match(expReg))
    return false;
  return true;
}
